<script>
import PhoneNumber from "@/components/PhoneNumber.vue";
import LanguageTag from "@/components/status/LanguageTag.vue";
import OrdersMixin from "@/views/orders/OrdersMixin.vue";
import ShowMore from "@/components/ShowMore.vue";
import TravelOrderStatusLabel from "@/views/trips/components/TravelOrderStatus.vue";
import { TravelOrderCancellationStatusMap } from "@/data/trip";

export default {
  name: "RouteInfo",
  components: { TravelOrderStatusLabel, ShowMore, LanguageTag, PhoneNumber },
  mixins: [OrdersMixin],
  props: {
    route: Object,
  },
  data() {
    return {
      expanded: true,
    };
  },
  computed: {
    driverRate() {
      return this.route.travelOrders.reduce((sum, order) => sum + (order.dryRunRate || order.rate), 0);
    },
  },
  methods: {
    toggleExpanded() {
      this.expanded = !this.expanded;
    },
    isTravelOrderCancelled(travelOrder) {
      return TravelOrderCancellationStatusMap.some((s) => s.id === travelOrder.status);
    },
  },
};
</script>

<template>
  <div class="rounded bg-white pa-4 mb-4">
    <div class="font-weight-500 text-grey-darken-2 mb-3">Route</div>
    <div v-if="route.$truck" class="rounded pa-4 bg-grey-lighten-4 position-relative">
      <div :class="{ expanded }" class="expand-button" @click="toggleExpanded">
        <v-icon color="primary" icon="mdi-chevron-down" size="16"></v-icon>
      </div>
      <div class="d-flex align-center">
        <router-link
          :to="{ name: 'truck-view', params: { id: route.$truck.id } }"
          class="text-primary text-decoration-none font-weight-500"
          data-qa="route-truck-number"
        >
          Truck {{ route.$truck.number }}
        </router-link>
        <div class="font-weight-500 font-size-12 ml-3 text-grey-darken-2" data-qa="route-total-driver-rate">
          Total driver rate: {{ driverRate }} $
        </div>
      </div>
      <div v-if="expanded">
        <div class="d-flex align-center mt-3 pb-4 border-b">
          <div
            class="rounded-circle text-uppercase text-white driver-label bg-indigo-lighten-2"
            data-qa="route-driver-label"
          >
            dr
          </div>
          <router-link
            :to="{ name: 'user-view', params: { id: route.$truck.driver.id } }"
            class="text-primary text-decoration-none ml-2"
            data-qa="route-driver-name"
          >
            {{ route.$truck.driver.name }}
          </router-link>
          <phone-number
            :number="route.$truck.driver.phone"
            class="text-primary ml-2"
            data-qa="route-driver-phone"
          ></phone-number>
          <language-tag :language="route.$truck.driver.language" class="ml-3" light></language-tag>
        </div>
        <div v-for="travelOrder in route.travelOrders" :key="travelOrder.id" class="mt-4">
          <template v-if="travelOrder.$order">
            <div class="d-flex justify-space-between">
              <div class="d-flex align-center">
                <div class="text-primary font-size-12 font-weight-500" data-qa="route-travel-order-number">
                  TO: #{{ travelOrder.id }}
                </div>
                <travel-order-status-label
                  v-if="isTravelOrderCancelled(travelOrder)"
                  :travel-order="travelOrder"
                  class="ml-2"
                  data-qa="route-travel-order-status"
                ></travel-order-status-label>
              </div>
              <div class="d-flex align-center">
                <div class="text-grey-darken-1 font-size-12 d-flex">
                  <span class="mr-1">REF:</span>
                  <show-more
                    :items="travelOrder.$order.refNumbers"
                    data-qa="route-ref-number"
                    item-class="text-grey-darken-1 font-size-12"
                  ></show-more>
                </div>
                <div class="text-grey-darken-2 font-size-12 font-weight-500 ml-4" data-qa="route-rate">
                  {{ travelOrder.rate }}$
                </div>
                <router-link
                  v-if="travelOrder.$order.id"
                  :to="{ name: 'order-view', params: { id: travelOrder.$order.id } }"
                  class="text-primary font-size-12 font-weight-500 ml-4 text-decoration-none"
                  data-qa="route-order-number"
                  target="_blank"
                >
                  Order: #{{ travelOrder.$order.number }}
                </router-link>
                <v-tooltip location="top">
                  <template #activator="{ props }">
                    <v-icon
                      v-if="travelOrder.$order.temperatures?.length || travelOrder.$order.protectFromFreezing"
                      class="ml-2"
                      color="info"
                      data-qa="route-temperature-control"
                      icon="mdi-snowflake"
                      size="16"
                      v-bind="props"
                    ></v-icon>
                  </template>
                  <template v-if="travelOrder.$order.protectFromFreezing">Protect from freezing</template>
                  <template v-else-if="travelOrder.$order.temperatures?.length">
                    Temperature control from {{ travelOrder.$order.temperatures[0] }} to
                    {{ travelOrder.$order.temperatures[1] }} ℉
                  </template>
                </v-tooltip>
              </div>
            </div>
            <div class="d-flex mt-3 justify-space-between">
              <div class="font-weight-500 text-grey-darken-3" data-qa="route-stop-road">
                {{ getOrderRoute(travelOrder.$order) }}
              </div>
              <router-link
                :to="{ name: 'customer-edit', params: { id: travelOrder.$order.customer.id } }"
                class="font-size-12 text-uppercase font-weight-500 text-primary text-decoration-none"
                data-qa="route-customer-name"
                target="_blank"
              >
                {{ travelOrder.$order.customer.name }}
              </router-link>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.expand-button {
  position: absolute;
  top: 8px;
  right: 8px;
  cursor: pointer;
  transition: all 250ms ease-in-out;

  &.expanded {
    transform: rotate(180deg);
  }
}

.driver-label {
  font-size: 9px;
  font-weight: 600;
  width: 16px;
  height: 16px;
  line-height: 16px;
  text-align: center;
}
</style>
