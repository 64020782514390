<script>
import OrdersMixin from "@/views/orders/OrdersMixin.vue";
import { DateTimeMixin } from "@/mixins/date-time.mixin";
import BaseView from "@/views/BaseView.vue";
import TruckDriverBlock from "@/views/trips/edit/blocks/TruckDriverBlock.vue";
import TrucksMixin from "@/views/trucks/TrucksMixin.vue";
import OmniTextarea from "@/components/inputs/Textarea.vue";
import OmniDialog from "@/components/OmniDialog.vue";
import { mapActions } from "pinia";
import { useGalleryStore } from "@/store/gallery.store";
import FacilityInfo from "@/views/trips/components/FacilityInfo.vue";
import { OrderFreightType } from "@/data/order";

export default {
  name: "DispatchDialog",
  components: { FacilityInfo, OmniDialog, OmniTextarea, TruckDriverBlock },
  mixins: [BaseView, DateTimeMixin, OrdersMixin, TrucksMixin],
  props: {
    modelValue: {
      type: Boolean,
      required: true,
    },
    orders: {
      type: Array,
      required: true,
    },
    route: {
      type: Object,
      required: true,
    },
  },
  emits: ["update:modelValue", "update:route", "change"],
  data() {
    return {
      dispatchRoute: { travelOrders: [], routeStops: [], sharedFiles: [] },
      files: [],
    };
  },
  computed: {
    ordersNotes() {
      return this.orders
        .map((o) => o.generalNote || "")
        .filter((text) => text.length > 0)
        .join("\n");
    },
    showDialog: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
    travelOrdersTotal() {
      let total = 0;
      for (const travelOrder of this.route.travelOrders) {
        total += parseFloat(travelOrder.rate);
      }
      return total;
    },
  },
  watch: {
    modelValue(value) {
      if (value) {
        this.prepareRoute();
      }
    },
  },
  mounted() {
    // For debug hot reload.
    if (this.modelValue) {
      this.prepareRoute();
    }
  },
  methods: {
    ...mapActions(useGalleryStore, ["setMedia", "setMediaId"]),
    getStopFreights(stop) {
      if (this.route.freights.length === 0) {
        return [];
      }
      return stop.freights.map((freightId) => this.route.freights.find((f) => f.id === freightId));
    },
    getStopPiecesNumber(stop) {
      return this.getStopFreights(stop).reduce(
        (quantity, f) => (f && f.type === OrderFreightType.pieces ? quantity + parseInt(f.quantity) : quantity),
        0,
      );
    },
    getStopPalletsNumber(stop) {
      return this.getStopFreights(stop).reduce(
        (quantity, f) =>
          f && f.type === OrderFreightType.pallets ? quantity + parseInt(f.quantity || f.plannedQuantity) : quantity,
        0,
      );
    },
    getStopWeight(stop) {
      return this.getStopFreights(stop).reduce(
        (totalWeight, f) => totalWeight + parseInt(f.weight || f.plannedWeight),
        0,
      );
    },
    prepareRoute() {
      this.dispatchRoute = { ...this.route };
      const sharedFiles = new Set(this.route.sharedFiles);
      const routeTag = "route:" + this.route.id + ":shared";

      const files = [];
      for (const travelOrder of this.route.travelOrders) {
        for (const file of this.getOrder(travelOrder).files) {
          // rate confirmation files can't be shared
          if (file.tags.includes("rateConfirmation")) continue;

          files.push({ ...file, sharedWithDriver: file.tags.includes(routeTag) || sharedFiles.has(file.id) });
        }
      }
      this.files = files;
    },
    preview(file) {
      this.setMedia([file]);
      this.setMediaId(file.id);
    },
    copyGeneralNotes() {
      this.dispatchRoute.driverNote = this.ordersNotes;
    },
    copyStopNote(stop) {
      stop.driverNote = stop.note;
    },
    async save() {
      const route = { ...this.dispatchRoute, sharedFiles: [] };

      for (const file of this.files) {
        if (file.sharedWithDriver) {
          route.sharedFiles.push(file.id);
        }
      }

      for (const stop of route.routeStops) {
        stop.dispatchedTimeType = stop.timeType;
        stop.dispatchedTimeFrom = stop.timeFrom;
        stop.dispatchedTimeTo = stop.timeTo;
      }
      route.$isDispatched = true;

      this.$emit("update:route", route);
      this.$emit("change", route);
      this.$emit("update:modelValue", false);
    },
    isStopUpdated(stop) {
      return !!stop.oldFacilityId;
    },
    getOrder(travelOrder) {
      return this.orders.find((o) => o.id === travelOrder.orderId);
    },
  },
};
</script>

<template>
  <omni-dialog
    v-model="showDialog"
    primary-action-label="Apply"
    title="Dispatch route"
    width="1280"
    @primary-action:click="save"
  >
    <div class="py-4">
      <div class="mb-8">
        <div
          v-for="(travelOrder, i) in dispatchRoute.travelOrders"
          :key="i"
          class="bg-grey-lighten-5 pa-3 rounded"
          style="border: 1px solid #e0e0e0"
        >
          <div class="d-flex align-center justify-space-between mb-4">
            <div class="d-flex">
              <div class="text-primary mr-4 font-weight-medium" data-qa="travel-order-number">
                TO #{{ travelOrder.number }}
              </div>
              <div class="text-grey-darken-2 font-weight-medium mr-4" data-qa="travel-order-rate">
                {{ travelOrder.rate }}$
              </div>
              <div
                v-if="getOrder(travelOrder).protectFromFreezing"
                class="d-flex align-center ml-4"
                data-qa="protect-from-freezing"
              >
                <v-icon color="info" icon="mdi-snowflake" size="16"></v-icon>
                <div class="ml-1 font-size-12 font-weight-500 text-grey-darken-2">Protect from freezing</div>
              </div>
              <div v-if="getOrder(travelOrder).underTracking" class="d-flex align-center ml-4" data-qa="under-tracking">
                <v-icon color="success" icon="mdi-map-marker-outline" size="16"></v-icon>
                <div class="ml-1 font-size-12 font-weight-500 text-grey-darken-2">Under tracking</div>
              </div>
              <div v-if="getOrder(travelOrder).hazmat" class="d-flex align-center ml-4" data-qa="hazmat-commodity">
                <v-icon color="warning" icon="mdi-biohazard" size="16"></v-icon>
                <div class="ml-1 font-size-12 font-weight-500 text-grey-darken-2">Hazmat commodity</div>
              </div>
            </div>
            <div class="d-flex">
              <div v-if="getOrder(travelOrder).teamForBroker" class="d-flex align-center mr-8">
                <v-icon color="grey-darken-2" icon="mdi-account-multiple" size="16"></v-icon>
                <div class="ml-1 font-size-12 font-weight-500 text-grey-darken-2">Team for broker</div>
              </div>
              <router-link
                :to="{ name: 'customer-edit', params: { id: getOrder(travelOrder).customer.id } }"
                class="font-size-12 font-weight-500 text-primary mr-8 text-decoration-none"
                data-qa="dispatch-route-customer"
                target="_blank"
              >
                {{ getOrder(travelOrder).customer.name }}
              </router-link>
              <router-link
                :to="{ name: 'order-view', params: { id: getOrder(travelOrder)?.id } }"
                class="text-primary font-weight-medium font-size-12 text-decoration-none"
                data-qa="dispatch-route-order-number"
                target="_blank"
              >
                Order #{{ getOrder(travelOrder)?.number }}
              </router-link>
            </div>
          </div>
          <div
            class="font-weight-medium mb-3 text-grey-darken-3 text-subtitle-1"
            data-qa="dispatch-route-address-route"
          >
            {{ getOrderRoute(getOrder(travelOrder)) }}
          </div>
          <div>
            <div class="d-flex align-center justify-space-between">
              <div class="font-size-16 text-grey-darken-1">
                <template v-if="getOrder(travelOrder).requiredDriverCertificates?.length">
                  Certificates:
                  <span class="font-weight-500 text-grey-darken-2" data-qa="dispatch-route-driver-certificates">
                    {{ getOrder(travelOrder).requiredDriverCertificates.join(", ") }}
                  </span>
                </template>
              </div>
              <div class="font-size-16 text-grey-darken-1">
                Check in as:
                <span class="font-weight-500 text-grey-darken-2" data-qa="dispatch-route-check-in-as">
                  {{ getOrder(travelOrder).checkInAs }}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="text-right mt-3">
          <div class="text-grey-darken-3">
            Total driver rate:
            <span class="font-weight-medium" data-qa="dispatch-route-total-driver-rate">{{ travelOrdersTotal }}$</span>
          </div>
        </div>
      </div>

      <div class="text-grey-darken-3 mb-3 font-weight-medium text-subtitle-1">General note</div>
      <div class="bg-grey-lighten-4 pa-3 rounded">
        <omni-textarea v-model="ordersNotes" data-qa="old-note" label="General note" readonly="readonly" rows="2">
        </omni-textarea>
        <div class="my-2 text-center">
          <v-btn color="primary" icon="mdi-arrow-down" size="20" variant="text" @click="copyGeneralNotes"></v-btn>
        </div>
        <omni-textarea
          v-model="dispatchRoute.driverNote"
          class="bg-white"
          data-qa="new-note"
          label="General note"
          rows="2"
        ></omni-textarea>
      </div>

      <div>
        <div class="text-grey-darken-3 mb-3 font-weight-medium text-subtitle-1 mt-4">Dispatch info</div>

        <div class="mb-8">
          <div v-for="(stop, i) in dispatchRoute.routeStops" :key="i" class="bg-grey-lighten-4 pa-3 mb-5 rounded">
            <v-row class="dispatch-info__blocks">
              <v-col class="dispatch-info__block">
                <div class="d-flex flex-column height-100 justify-space-between">
                  <div>
                    <div class="d-flex justify-space-between">
                      <div class="d-flex align-center flex-wrap mb-6">
                        <span class="d-flex align-center mr-8">
                          <v-icon v-if="stop.type === STOP_PICK_UP" class="mr-2" color="green" size="20">
                            mdi-package-variant-closed
                          </v-icon>
                          <v-icon v-if="stop.type === STOP_RELOAD" class="mr-2" color="orange" size="20">
                            mdi-package-variant-closed
                          </v-icon>
                          <v-icon v-if="stop.type === STOP_DELIVERY" class="mr-2" color="red accent-2" size="20">
                            mdi-map-marker-radius-outline
                          </v-icon>
                          <span
                            class="font-weight-600 text-blue-grey-darken-1 font-size-12"
                            data-qa="dispatch-info-stop-type"
                          >
                            {{ stop.name }}
                          </span>
                        </span>
                        <span
                          v-if="getStopPiecesNumber(stop)"
                          class="d-flex align-center font-size-12 font-weight-medium text-grey-darken-2 mr-4"
                        >
                          <svg class="mr-2" height="16" width="16">
                            <use xlink:href="@/assets/images/pallet-small.svg#pallet-small"></use>
                          </svg>
                          {{ getStopPiecesNumber(stop) }} pieces
                        </span>
                        <span
                          v-if="getStopPalletsNumber(stop)"
                          class="d-flex align-center font-size-12 font-weight-medium text-grey-darken-2 mr-4"
                        >
                          <svg class="mr-2" height="16" width="16">
                            <use xlink:href="@/assets/images/pallet-small.svg#pallet-small"></use>
                          </svg>
                          {{ getStopPalletsNumber(stop) }} pallets
                        </span>
                        <span class="d-flex align-center font-size-12 text-grey-darken-2 font-weight-medium mr-8">
                          <svg class="mr-2" height="16" width="16">
                            <use xlink:href="@/assets/images/weight-small.svg#weight-small"></use>
                          </svg>
                          {{ getStopWeight(stop) }} lbs
                        </span>
                        <router-link
                          v-if="stop.order"
                          :to="{ name: 'order-view', params: { id: stop.order?.id } }"
                          class="text-primary font-size-12 font-weight-medium text-decoration-none"
                          data-qa="dispatch-info-order-number"
                          target="_blank"
                        >
                          Order #{{ stop.order?.number }}
                        </router-link>
                      </div>
                      <div class="d-flex align-center">
                        <div
                          class="d-flex align-center font-weight-medium text-grey-darken-1 mb-6"
                          data-qa="dispatch-info-stop-time"
                        >
                          <v-icon class="mr-2" size="20">mdi-clock-outline</v-icon>
                          Route stop time - {{ timeTypesMap[stop.timeType] }}: {{ getStopTime(stop) }}
                        </div>
                        <div
                          v-if="isStopUpdated(stop)"
                          class="badge bg-warning pa-2 rounded-te rounded-bs font-weight-600 ml-4"
                        >
                          UPDATED
                        </div>
                      </div>
                    </div>
                    <div v-if="stop.facility">
                      <facility-info :facility="stop.facility" :updated="stop.oldFacilityId"></facility-info>
                    </div>
                  </div>
                </div>
              </v-col>
            </v-row>
            <v-row class="align-center">
              <v-col class="pr-2">
                <omni-textarea
                  v-model="stop.note"
                  data-qa="order-stop-note"
                  label="Order stop note"
                  readonly
                  rows="2"
                />
              </v-col>
              <v-col class="pa-0 arrow-container">
                <v-btn
                  color="primary"
                  icon="mdi-arrow-right"
                  size="20"
                  variant="text"
                  @click="copyStopNote(stop)"
                ></v-btn>
              </v-col>
              <v-col class="pl-2">
                <omni-textarea
                  v-model="stop.driverNote"
                  class="bg-white"
                  data-qa="driver-instruction"
                  label="Driver instructions"
                  rows="2"
                />
              </v-col>
            </v-row>
          </div>
        </div>

        <div class="mb-8">
          <div class="mb-6">
            <div class="text-grey-darken-3 font-weight-medium text-subtitle-1">Files</div>
            <div class="text-grey-darken-2 mt-3">Select or upload the files that will be sent to the driver</div>
          </div>
          <div>
            <v-row>
              <v-col v-for="file in files" :key="file.id" lg="3" md="4" sm="6" xl="3" xs="12" xxl="3">
                <div class="rounded d-flex flex-column" style="border: 1px solid #e0e0e0; height: 364px">
                  <div class="overflow-hidden">
                    <img :src="getPreviewPath(file)" alt="" class="w-100 rounded-t" />
                  </div>
                  <div class="pa-4" style="border-top: 1px solid #e0e0e0">
                    <div class="mb-4 overflow-hidden">
                      <v-checkbox v-model="file.sharedWithDriver" color="primary" density="compact" hide-details>
                        <template #label>
                          <div class="ml-2 d-flex align-center">
                            <v-icon class="mr-2" color="primary" size="20">mdi-file-document-outline</v-icon>
                            <span class="text-grey-darken-4">{{ file.name }}</span>
                          </div>
                        </template>
                      </v-checkbox>
                    </div>
                    <v-btn
                      block="block"
                      class="text-uppercase"
                      color="primary"
                      variant="outlined"
                      @click="preview(file)"
                    >
                      View
                    </v-btn>
                  </div>
                </div>
              </v-col>
            </v-row>
          </div>
        </div>

        <div v-if="dispatchRoute.truck" class="mb-8">
          <div class="d-flex align-center justify-space-between mb-3">
            <div class="text-grey-darken-3 font-weight-medium text-subtitle-1">Dispatching contacts</div>
            <div class="d-flex flex-wrap">
              <router-link
                :to="{ name: 'truck-view', params: { id: dispatchRoute.truck.id } }"
                class="text-primary text-decoration-none mr-3"
                data-qa="dispatching-contact-truck-number"
                target="_blank"
              >
                Truck {{ dispatchRoute.truck.number }}
              </router-link>
              <span class="text-grey-darken-2" data-qa="dispatching-contacts-freights-summary">
                {{ getTruckType(dispatchRoute.truck.type) }}, {{ dispatchRoute.truck.trailer.length }}″ x
                {{ dispatchRoute.truck.trailer.height }}″ x {{ dispatchRoute.truck.trailer.width }}″,
                {{ dispatchRoute.truck.trailer.payload }} lbs
              </span>
            </div>
          </div>
          <div>
            <v-row>
              <v-col v-if="dispatchRoute.truck?.driver" lg="3" md="4" sm="6" xl="3" xs="12" xxl="3">
                <truck-driver-block :driver="dispatchRoute.truck.driver" />
              </v-col>

              <v-col v-if="dispatchRoute.truck?.driver.contactPerson" lg="3" md="4" sm="6" xl="3" xs="12" xxl="3">
                <truck-driver-block :driver="dispatchRoute.truck.driver.contactPerson" is-financially-responsible />
              </v-col>
            </v-row>
          </div>
        </div>
      </div>
    </div>
  </omni-dialog>
</template>

<style lang="scss" scoped>
.arrow-container {
  flex: 0 0 20px;
}

.badge {
  height: 36px;
  margin-top: -32px;
  margin-right: -12px;
}
</style>
